import React from 'react';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import arrow from 'img/ui/arrow_white.svg';

const WhatIsUX = () => {
  return (
    <>
      <h2>How do you explain what UX is?</h2>
      <p>
        We’ve asked 10 business experts to define user experience. Here is their take on the
        subject.
      </p>
      <h3>
        1. When it comes to a user experience definition, it’s how people interact with a product
        throughout its entire lifecycle.
      </h3>
      <blockquote>
        <p>
          <em>
            When it comes to defining user experience, it&apos;s how people interact with a product
            (whether that be a program or a microwave oven) throughout its entire lifecycle.
          </em>
        </p>
        <p>
          <em>
            It&apos;s usually used in the context of someone working as a UX designer/specialist of
            some sort, and in theory is the noble art of optimizing aesthetics appeal, ease of use,
            and overall customer satisfaction with that product.{' '}
          </em>
        </p>
        <p>
          <em>
            It is the responsibility of a UX designer to conceive the user-experience from a
            viewer&apos;s point so that a visitor can enjoy an enhanced interactive atmosphere.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/in/muhammed-mateen-khan-286383107/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Muhammad Mateen Khan
            </Link>
            , Digital Marketing Strategist at{' '}
            <Link
              href="https://www.purevpn.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              PureVPN
            </Link>
          </strong>
        </p>
      </blockquote>
      <h3>
        2. What is user experience design? It’s about how you make your users feel, right from the
        moment they first come in contact with your brand.{' '}
      </h3>

      <blockquote>
        <p>
          <em>
            It was Maya Angelou who said, &quot;I&apos;ve learned that people will forget what you
            said, people will forget what you did, but people will never forget how you made them
            feel.&quot; <strong>What is user experience</strong>? It has to do with how you make
            your users feel, right from the moment they first come in contact with your brand. It is
            the sum total of how they feel about their encounter with you.
          </em>
        </p>
        <p>
          <em>
            At Astute Copy Blogging, we seek to provide amazing user experience, also known as
            customer experience. We do this by making our customers feel unique and special. A great
            user experience invariably leads to increased customer loyalty, increased customer
            satisfaction, better word-of-mouth marketing, positive reviews, and recommendations.
            Here is the reality. The better the experience customers have the more repeat custom and
            positive reviews a business would receive.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/in/pedrookoro/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Pedro Okoro
            </Link>
            , pro blogger, List-grow strategist, and CEO of{' '}
            <Link
              href="https://www.astutecopyblogging.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Astute Copy Blogging
            </Link>
          </strong>
        </p>
      </blockquote>

      <h3>3. The absence of negative experiences is what good user experience is.</h3>

      <blockquote>
        <p>
          <em>
            You know when you download a new app, and then immediately consider throwing your phone
            into the sea because you can’t figure out how to do that one thing, and an influx of ads
            keep spamming your screen? Good UX is the absence of that type of experience.
          </em>
        </p>
        <p>
          <em>
            Good UX is like going on a great first date that leads to a successful long-term
            relationship. The experience is interactive, the conversation flows, and you just get
            each other.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/in/kimberly-smith-0829625/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Kimberly Smith
            </Link>
            , Marketing Manager at{' '}
            <Link
              href="https://clarifycapital.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Clarify Capital
            </Link>
          </strong>
        </p>
      </blockquote>

      <h3>
        4. My user experience definition comes down to how you make your users feel when they
        consume your content.{' '}
      </h3>

      <blockquote>
        <p>
          <em>
            It goes beyond simply achieving the primary purpose, but rather aims to engage users and
            improve behavioral and attitudinal loyalty. The user experience should be at the
            forefront of any digital strategy, aiming to ensure that users have a positive
            experience when exposed to your business.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/in/itamarblauer/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Itamar Blauer
            </Link>
            , SEO Consultant at{' '}
            <Link
              href="https://www.itamarblauer.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              ItamarBlauer
            </Link>
          </strong>
        </p>
      </blockquote>
      <h3>
        5. What is user experience design? At its core, good UX is all about delighting the clients.
      </h3>

      <blockquote>
        <p>
          <em>
            User Experience often is thought of in terms of user interaction with a web or software
            application. However, UX goes much deeper than just the technical features or layout of
            our product. At its core, good UX is all about delighting the clients. The focus should
            be on the customer rather than on the application or solution.
          </em>
        </p>
        <p>
          <em>
            Alpine XC, a small dirt bike repair shop with no real tech presence recently reminded me
            that UX is not a technical problem, but a customer-focused problem. Alpine XC has
            adjusted to the COVID-19 pandemic by offering mobile service and repairs so customers
            don’t have to come to the shop, but instead, have the mechanics come to them. To me,
            this exemplifies what User Experience is about. It doesn’t need to be a technical
            solution… it needs to make the process simple and pain-free for your customers.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/in/trent-mcmillan/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Trent McMillan
            </Link>
            , Product Manager at{' '}
            <Link
              href="https://www.alpinexc.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Alpine XC
            </Link>
          </strong>
        </p>
      </blockquote>
      <h3>
        6. When it comes to defining user experience, I see it as roller coaster design; it’s the
        neverending battle between satisfying your fans and growth through reversion to the mean.
      </h3>

      <blockquote>
        <p>
          <em>
            User experience is roller coaster design. Products are like roller coasters because
            riders know where it starts and ends. When I open Uber, I know it ends with me getting a
            ride. The journey of the roller coaster becomes the user experience. Most roller
            coasters are designed for mass appeal and end up formulaic. Every so often, you find a
            roller coaster that is, in one or more dimensions, far too extreme for mass appeal. If
            it survives, it becomes a cult classic. User experience, and ultimately customer
            centricity, is the neverending battle between satisfying your adoring fans and growth
            through reversion to the mean.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/in/jeffreyyzhou/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Jeff Zhou
            </Link>
            , CEO at{' '}
            <Link
              href="https://www.figloans.com/community-loans"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Fig
            </Link>
          </strong>
        </p>
      </blockquote>
      <h3>
        7. User experience is built on every time your customer sees something about you in the
        press, receives and email, or interacts with you in any way whatsoever.
      </h3>
      <blockquote>
        <p>
          <em>
            User experience is how your customer feels when interacting with your brand. Do they
            feel flustered because your website is difficult to navigate? Do they feel important
            because you are always excited and happy to see them in meetings? It is easy to plan to
            give your customers good user experience, but the importance of this word goes beyond
            the standard dictionary UX definition. User experience is built on every time your
            customer sees something about you in the press, receives an email, or interacts with you
            in any way whatsoever.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/company/directiondotcom"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Cali Saturn
            </Link>
            , Digital Marketing Specialist at{' '}
            <Link href="https://direction.com" target="_blank" rel="noopener noreferrer nofollow">
              Direction.com
            </Link>
          </strong>
        </p>
      </blockquote>
      <h3>
        8. User experience is how efficiently the average user can navigate around without getting
        lost.
      </h3>
      <blockquote>
        <p>
          <em>
            We define user experience as how efficiently the average user of a site can navigate
            around a site and complete their intended action without getting lost. The intended
            action varies based on the type of site and business model - for example, what applies
            to an e-commerce platform and shopping experience is vastly different from
            recommendations we might provide to a local attorney&apos;s site.
          </em>
        </p>
        <p>
          <em>
            Once we understand the type of business we&apos;re dealing with, we then jump into the
            analytics to understand the metrics around a user&apos;s experience. This includes time
            on site, average number of pages consumed, bounce rates, exit rates and abandon cart
            rates (for online stores). With a comprehensive set of data, we then optimize a
            website&apos;s hierarchy, its page flows, navigation, color palettes, font sizes for
            important elements on a page and anything else we can identify as where a user is
            getting lost.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/in/jeffromero/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Jeff Romero
            </Link>
            , Co-Founder at{' '}
            <Link
              href="https://www.octivdigital.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Octiv Digital
            </Link>
          </strong>
        </p>
      </blockquote>
      <h3>
        9. We define user experience as everything that helps users fall in love with our product.
      </h3>
      <blockquote>
        <p>
          <em>
            Designers would say that user experience is how a product makes you feel. Analysts would
            say it’s how quickly you complete key workflows. Product managers would say it’s about
            retention and recurring revenue.
          </em>
        </p>
        <p>
          <em>
            For our business, it’s all of these things combined. We take a holistic approach to user
            experience and define it as everything that helps users fall in love with our product.
          </em>
        </p>
        <p>
          <em>
            This approach keeps everyone in our team aligned around the user experience and
            motivates them to do their best work, as they recognize how their contribution leads to
            an overall positive experience for our users.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/in/matthew-johnson-6a58b82/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Matthew Johnson
            </Link>
            , Product Manager at{' '}
            <Link
              href="https://www.userback.io/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              userback.io
            </Link>
          </strong>
        </p>
      </blockquote>
      <h3>
        10. UX is a combination of facets like functionality, design, usability and branding, all
        into one coherent story, and bringing it into the user’s world in a balanced way.
      </h3>
      <blockquote>
        <p>
          <em>
            What is User Experience Design? For me, it’s the combination of facets such as
            functionality, design, usability, and branding, all into one coherent story, and then
            bringing it into the user&apos;s world in a balanced way.
          </em>
        </p>
        <p>
          <em>
            In the whole process of creating, developing, and promoting products, users are always
            at the top. It doesn&apos;t matter how innovative the features you come up with or how
            aesthetic the design you create. If your users don&apos;t feel it, you can&apos;t count
            on success.
          </em>
        </p>
        <p>
          <em>
            Users should have a sense that they&apos;ve always lacked something and that
            they&apos;ve actually longed for your product. It meets their needs, solves their
            problems, keeps them happy, and thus makes it difficult for them to understand how they
            could have been without it.
          </em>
        </p>
        <p>
          <em>
            Giving users a meaningful experience wrapped in the product makes it an integral part of
            their lives.
          </em>
        </p>
        <p>
          <strong>
            <Link
              href="https://www.linkedin.com/in/weronika-masternak/ "
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Weronika Masternak
            </Link>
            , Content Marketing Specialist at{' '}
            <Link
              href="https://www.helpdesk.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              HelpDesk
            </Link>
          </strong>
        </p>
      </blockquote>
      <h2>Summary</h2>
      <p>
        As you’ve just seen in the examples presented above, defining user experience doesn’t have
        to be confined to a standard, Wikipedia-style answer! In fact, depending on your experiences
        with creating products it can mean a lot of things. UX can be anything from the sense of
        building a product users feel they’ve longed for, through increasing the efficiency of how
        people finalize processes in an interface, all the way through bringing functionality,
        design, usability, and branding into a perfectly balanced product.
      </p>
      <p>
        How about you – how do you define{' '}
        <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
          user experience
        </Link>
        ? If you’d like to add your very own UX definition, don’t hesitate to reach out!
      </p>
      <div className="next-chapter">
        <Link href="/user-experience/ux-design-process/">
          <div>
            <p>Chapter 2</p>
            <p>UX Design Process: What to Include and How to Do It Right</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(WhatIsUX);
